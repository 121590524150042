/* stylelint-disable no-descending-specificity */
@import 'libs/styles/src/common/common';

@layer shared-component {
  .focus-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    text-decoration: none;
    position: relative; // Fix for inner div in a button for macOS

    @mixin smallXPadding {
      &.small-x-padding {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    @mixin noXPadding {
      &.no-x-padding {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.padding {
      &.normal {
        padding: 0.75rem 1.375rem;

        @include smallXPadding;
        @include noXPadding;

        @include media-breakpoint-only(xs) {
          padding: 0.6rem 1.25rem;

          @include noXPadding;
        }
      }

      &.none {
        padding: 0;

        @include media-breakpoint-only(xs) {
          padding: 0;
        }
      }

      &.juicy {
        padding: 0.875rem 1.5rem;

        @include noXPadding;
      }

      &.large {
        padding: 0.375rem 1.375rem;

        @include noXPadding;
      }

      &.box {
        padding: 0.3rem 0.8rem;

        @include noXPadding;
      }

      &.thin {
        padding: 0.375rem 1.375rem;
        font-size: 13px;
        font-weight: 500;

        @include noXPadding;

        @include media-breakpoint-only(xs) {
          padding: 0.375rem 1.25rem;

          @include noXPadding;
        }
      }

      &.capsule {
        padding: 0.25rem 0.375rem;
        font-size: 13px;
        font-weight: 500;

        @include noXPadding;

        @include media-breakpoint-only(xs) {
          padding: 0.375rem 1.25rem;

          @include noXPadding;
        }
      }
    }

    // Shapes (Duplicated for skeleton)
    &.round {
      border-radius: 90px;
    }

    &.curved {
      border-radius: 10px;
    }
  }

  .button {
    font: inherit;
    color: inherit;
    padding: 0;
    border: none;
    background: none;
    font-weight: 800;
    letter-spacing: 2px;
    font-size: 16px;
    outline: none;
    cursor: auto;

    // Button Types
    &.large {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }

    &.thin {
      letter-spacing: 1px;
      line-height: 24px;
    }

    &.capsule {
      letter-spacing: 1px;
      line-height: unset;
    }

    &.normal {
      font-size: 14px;
      font-weight: 800;
      line-height: 18px;
    }

    &.box {
      font-weight: 600;
      font-size: 20px;
      letter-spacing: -0.02em;
      line-height: 24px;
    }

    &.none {
      font-weight: inherit;
      font-size: inherit;
      letter-spacing: inherit;
      line-height: 1;
    }

    &:not(.disabled) {
      cursor: pointer;
    }

    // Focus
    &.force-underline:focus > .focus-container,
    &.underline:focus > .focus-container {
      text-decoration: underline;
    }

    &.outline:focus > .focus-container {
      outline: auto 5px -webkit-focus-ring-color;
    }

    // Colors
    &.transparent {
      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }
    }

    &.transparent-blue {
      & > .focus-container {
        color: $blue;
        margin: 2px;
      }

      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }
    }

    &.blue {
      & > .focus-container {
        background-color: $blue;
        color: #fff;
        margin: 2px;
      }

      &.background:focus > .focus-container {
        background-color: $blue-hover;
      }

      &.disabled {
        // invalid-blue
        & > .focus-container {
          background-color: unset;
          color: $blue;
          border: 1px solid #d6dcf1;
          margin: 1px;
        }

        &.background:focus > .focus-container {
          background-color: $button-light-blue-alt-1;
        }
      }
    }

    &.dark-blue {
      & > .focus-container {
        background-color: $dark-blue-text;
        color: #fff;
        margin: 2px;
      }

      &.background:focus > .focus-container {
        background-color: $dark-blue-alt-1;
      }
    }

    &.invalid-blue {
      & > .focus-container {
        background-color: unset;
        color: $blue;
        border: 1px solid #d6dcf1;
        margin: 1px;
      }

      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }
    }

    &.invalid-grey {
      & > .focus-container {
        background-color: unset;
        color: $light-grey-text;
        border: 1px solid $light-grey-text;
        margin: 1px;
      }

      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }
    }

    &.red {
      font-weight: 700;

      & > .focus-container {
        background-color: $red;
        color: #fff;
        margin: 2px;
      }

      &.background:focus > .focus-container {
        background-color: rgba($red, 0.8);
      }

      &.disabled {
        // invalid-red
        & > .focus-container {
          background-color: unset;
          color: $red;
          border: 1px solid #d6dcf1;
          margin: 1px;
        }

        &.background:focus > .focus-container {
          background-color: rgba($red, 0.2);
        }
      }
    }

    &.invalid-red {
      & > .focus-container {
        background-color: unset;
        color: $red;
        border: 1px solid #d6dcf1;
        margin: 1px;
      }

      &.background:focus > .focus-container {
        background-color: rgba($red, 0.2);
      }
    }

    &.light-blue {
      & > .focus-container {
        background-color: $button-secondary-blue;
        color: $blue;
        margin: 2px;
      }

      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }
    }

    &.invalid-light-blue {
      & > .focus-container {
        background-color: unset;
        color: $blue;
        border: 1px solid #d6dcf1;
        margin: 1px;
      }

      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }
    }

    &.inverted-blue {
      & > .focus-container {
        background-color: $light-grey-blue;
        color: $blue;
        border: 2px solid $blue;
      }

      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }

      &.disabled {
        & > .focus-container {
          background-color: $light-grey-blue;
          color: rgba($blue, 0.8);
          border: 1px solid #d6dcf1;
          margin: 1px;
        }

        &.background:focus > .focus-container {
          background-color: unset;
        }
      }
    }

    &.inverted-red {
      & > .focus-container {
        background-color: $super-light-red;
        color: $red;
        border: 2px solid $red;
      }

      &.background:focus > .focus-container {
        background-color: #fdf2df;
        color: $red;
        border: 2px solid $red;
      }

      &.disabled {
        & > .focus-container {
          background-color: $super-light-red;
          color: rgba($red, 0.8);
          border: 1px solid #d6dcf1;
          margin: 1px;
        }

        &.background:focus > .focus-container {
          background-color: unset;
        }
      }
    }

    &.white-blue {
      & > .focus-container {
        background-color: #fff;
        color: $blue;
        border: 2px solid $blue;
      }

      &.background:focus > .focus-container {
        background-color: $light-grey;
      }
    }

    &.wide-bordered-light-blue {
      & > .focus-container {
        background-color: unset;
        color: $blue;
        border: 3px solid #d6dcf1;
      }

      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }
    }

    &.thin-grey-border {
      & > .focus-container {
        font-weight: 600;
        background-color: $white;
        color: $dark-blue;
        border: 1px solid $light-grey-text;
      }

      &.background:focus > .focus-container {
        background-color: $button-light-blue-alt-1;
      }
    }

    &.white {
      & > .focus-container {
        background-color: white;
        color: $blue;
        border: 3px solid #d6dcf1;
      }

      &.background:focus > .focus-container {
        background-color: white;
      }
    }

    &.grey {
      & > .focus-container {
        border: 1px solid $light-grey-text;
      }

      &.background:focus > .focus-container {
        background-color: white;
      }
    }

    // Skeleton
    &.skeleton {
      overflow: hidden;

      // Shapes
      &.round {
        border-radius: 90px;
      }

      &.curved {
        border-radius: 10px;
      }
    }
  }

  .label {
    color: inherit;

    &.no-wrap {
      white-space: nowrap;
    }
  }

  .icon {
    pointer-events: none;
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    position: relative;

    > svg,
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .divider {
    margin-right: 6px;
  }
}
