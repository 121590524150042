@import 'libs/styles/src/common/common';

@layer component {
  .header {
    padding: 1rem 3rem;
    position: relative;
    z-index: 1042; // one more then .menu-overlay
    // background: #f7f7f7;

    @include media-breakpoint-down(md) {
      padding: 1rem 2rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 0.5rem 2rem 1rem;
    }

    @include media-breakpoint-down(xs) {
      padding: 0.5rem 0 1rem 1rem;
    }
  }

  :global(.page-home) {
    .header-container {
      background: $dark-blue;
    }
  }

  .menu-overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1041; // One less then .header
  }

  .backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--form-label-color, $form-label);
    z-index: 1040; // One less then .menu-overlay
  }

  .btn {
    // padding: 0 !important;
    width: 48px !important;
    height: 48px !important;
  }

  .initial {
    height: 32px;
    width: 32px;
    letter-spacing: normal;
    border-radius: 50%;
    background: $blue-alt-1;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    box-shadow: $small-shadow;
  }

  .header-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 44px;

    .right {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > li {
        align-self: center;
      }
    }
  }

  .logo-container {
    $width: 240px;

    z-index: 1031;
    width: $width;
    height: round(calc($width / 4.7));
    margin: -1rem 0 -1rem -1rem;

    @include media-breakpoint-down(sm) {
      $width: 220px;

      width: $width;
      height: round(calc($width / 4.7));
    }

    @include media-breakpoint-down(xs) {
      margin: -1rem 0;

      $width: 160px;

      width: $width;
      height: round(calc($width / 4.7));
    }

    .logo {
      padding-right: 0;
      height: auto;
      width: 100%;
      max-height: 100%;
    }
  }

  .search {
    justify-content: center;
    display: flex;

    @media (max-width: 575.98px), (max-height: 360px) {
      //media-breakpoint-down(xs) + max-height
      display: none;
    }

    @media (max-width: 1275px) {
      //media-breakpoint-down(xs) + max-height
      order: 3;
      flex-basis: 100% !important;
      padding-top: 15px;
    }
  }

  .show-search {
    display: flex !important;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .header-button {
    z-index: 1031;
  }

  .popover-container {
    position: sticky;
    top: 24px;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 0;

    &--profile {
      top: -14px;
      right: 168px;

      @include media-breakpoint-down(sm) {
        right: 152px;
      }

      @include media-breakpoint-down(xs) {
        right: 120px;
      }
    }
  }

  .notification-container {
    position: fixed;
    top: 24px;
    left: 0;
    width: 100%;
    z-index: 1051;
    height: 0;
  }

  .alert {
    &-danger {
      color: $red;
      border: none;
      background: rgb(245 46 77 / 10%);
    }

    &-success {
      color: $blue;
      border: none;
      background: $pale-blue;
    }
  }
}
